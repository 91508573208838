import { Password } from '@mui/icons-material'
import * as Yup from 'yup'
import { PATTERNS } from '../helpers/constants/ValidationPatterns'
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


export const signupValidation = Yup.object({
  email: Yup.string().email().required('Email required'),
  companyName: Yup
    .string()
    .min(3, 'Characters should be minimum 3')
    .required('Company name required'),
  password: Yup
    .string()
    .matches(
      PATTERNS.PASSWORD,
      'Must contain 8 characters, one lowercase, one uppercase, one number and one special case character'
    )
    .required('Password required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
})


export const accountDetailsYup = Yup.object({
  email: Yup.string().email().required('Email required'),
  firstName: Yup
    .string()
    .min(3, 'Characters should be minimum 3')
    .max(15, 'Maximum 15')
    .required('First name required'),
  lastName: Yup
    .string()
    .min(3, 'Characters should be minimum 3')
    .max(15, 'Maximum 15')
    .required('Last name required'),
  phone: Yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(10, 'Phone number should be 10 digit')
    .max(10, 'Phone number should be 10 digit')
    .required('Phone required'),
})

export const companyDetailsYup = Yup.object({
  companyName: Yup.string().required('Company name required'),
  country: Yup.string().required('Country required'),
  city: Yup.string().required('City required'),
  fax: Yup.string(),
  state: Yup.string().required('State required'),
  zip: Yup
    .string()
    .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits')
    .required('Zip code is required'),
  phone: Yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(10, 'Phone number should be 10 digit')
    .max(10, 'Phone number should be 10 digit')
    .required('Phone required'),
  street: Yup.string().notRequired(),
  streetNumber: Yup.string().required('Address - Line 1 required'),
})

export const billingDetailsYup = Yup.object({
  email: Yup.string().email().required('Email required'),
  firstName: Yup.string().required('First name required'),
  lastName: Yup.string().required('Last name required'),
  mobile: Yup
    .string()
    .matches(phoneRegExp, 'Mobile number is not valid')
    .min(10, 'Mobile number should be 10 digit')
    .max(10, 'Mobile number should be 10 digit'),
  fax: Yup.string(),
  phone: Yup
    .string()
    .required('Phone required')
    .min(10, 'Phone number should be 10 digit')
    .max(10, 'Phone number should be 10 digit'),
  phoneExtension: Yup.string(),
  companyName: Yup.string().required('Company name required'),
  country: Yup.string().required('Country required'),
  state: Yup.string().required('State required'),
  city: Yup.string().required('City required'),
  zip: Yup
    .string()
    .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits')
    .required('Zip code is required'),
  line1: Yup.string().required('Address 1 required'),
  line2: Yup.string().notRequired(),
})

export const creditCardYup = Yup.object({
  firstName: Yup.string().required('First name required'),
  lastName: Yup.string().required('Last name required'),
  cardNumber: Yup
    .string()
    .min(16, 'Number should be 16 digit')
    .max(16, 'Number should be 16 digit')
    .required('Card number required'),
  expriationMonth: Yup
    .string()
    .required('Month required')
    .matches(/^(0?[1-9]|1[0-2])$/, 'Invalid month')
    .test(
      'future-month',
      'Expiry Month cannot be in the past',
      function (value) {
        const currentYear = new Date().getFullYear()
        const currentMonth = new Date().getMonth() + 1
        const expirationYear = this.parent.expirationYear
        if (
          parseInt(expirationYear, 10) === currentYear &&
          parseInt(value, 10) < currentMonth
        ) {
          return false
        }
        return true
      }
    ),
  expirationYear: Yup.string()
    .min(4, 'Year should be 4 digits')
    .max(4, 'Year should be 4 digits')
    .required('Year required')
    .test('future-year', 'Expiry Year cannot be in the past', (value) => {
      const currentYear = new Date().getFullYear()
      return parseInt(value, 10) >= currentYear
    }),
  cvv: Yup.string()
    .min(3, 'CVV should be at least 3 digits')
    .max(4, 'CVV should be at most 4 digits')
    .required('CVV required'),
})

export const bankAccountValidationYup = Yup.object({
  accountName: Yup.string().required('required'),
  accountNumber: Yup.string().required('required'),
  routingNumber: Yup.string().required('required'),
  accountType: Yup.string().required('required'),
  bankName: Yup.string().required('required'),
  checkNumber: Yup.string().required('required'),
})
