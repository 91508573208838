import React, { useState } from 'react'

// react-router
import { useNavigate } from 'react-router-dom'

//Logging
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'

// jwt - decode
import jwt_decode from 'jwt-decode'

// formik
import { Formik, Form } from 'formik'
import ReusableTextfield from '../textField/textField'
import * as Yup from 'yup'

// mui
import {
  Grid,
  Box,
  Stack,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material'

// styles
import { styled } from '@mui/system'

// logo
import Logo from '../../../assets/Altigen_logo_with_text.png'
import MSLogo from '../../../assets/MicrosoftLogo.png'

// Icons
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

// components
import { useToastContext } from '../../../App'

// sso
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../../../helpers/AuthConfiguration'

// api
import { commonLogin, ssoLogin } from '../../../api/post/postApi'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux'
import {
  normalizeApiResponse,
  redirectionFunction,
  saveToken,
} from '../../../helpers/HelperFunctions'
import { setIsMaster } from '../../../store/slice/commonSlice'
import { COMPANY_LEVELS } from '../../../helpers/Constants'

const StyledSpan = styled('span')(({ theme }) => ({
  fontSize: '15px',
  color: theme.palette.primary.main,
  '&:hover': {
    cursor: 'pointer',
  },
}))

const StyledMS = styled('span')(({ theme }) => ({
  color: '#0984E3',
  fontSize: '14px',
  fontWeight: theme.typography.fontWeightMedium,
  textAlign: 'left',
  lineHeight: '0px',
  fontStyle: 'normal',
  letterSpacing: '0px',
}))

// formik validations
const initialValues = {
  email: '',
  password: '',
}

const FormValidation = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email.')
    .required('Please enter your email'),
  password: Yup.string().required('Please enter your password'),
})

const CommonLogin = (props) => {
  //Contexts
  const appInsights = useAppInsightsContext()
  const { dispatch } = useCommonRedux()
  const { toast } = useToastContext()
  const { instance } = useMsal()

  const [processingSSO, setProcessingSSO] = useState(false)
  const [showPassword, setshowPassword] = useState(false)
  const [loading, isLoading] = useState(false)
  const navigate = useNavigate()

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword)
  }

  // sso functions
  const handleSSOLogin = async (instance) => {
    try {
      setProcessingSSO(true)
      const ssoResponse = await instance.loginPopup(loginRequest)
      await accountLoginSSO(ssoResponse)
    } catch (error) {
      toast.showToast(normalizeApiResponse(error)?.message || 'Cancel by user', 'error')
    } finally {
      setProcessingSSO(false)
    }
  }

  const accountLoginSSO = async (ssoResponse) => {
    try {
      const ssoToken = { token: ssoResponse.accessToken }
      const apiResponse = await ssoLogin(ssoToken)
      if (apiResponse) {
        localStorage.setItem('ssoHomeID', ssoResponse.account.homeAccountId)
        saveToken(apiResponse.data.result.token)
        await loginFunction(apiResponse.data.result.token, {
          email: ssoResponse.account.username,
        })
      }
    } catch (error) {
      appInsights.trackException({ exception: new Error(error.message) })
      toast.showToast(
        normalizeApiResponse(error)?.message,
        'error'
      )
    }
  }

  const accountLogin = async (values) => {
    try {
      isLoading(true)
      const apiResponse = await commonLogin(values)
      if (apiResponse) {
        const token = apiResponse.data.result.token
        loginFunction(token, values)
      }
    } catch (error) {
      appInsights.trackException({ exception: new Error(error.message) })
      toast.showToast(
        normalizeApiResponse(error)?.message,
        'error'
      )
      navigate('/login') //persisting the login page
    } finally {
      isLoading(false)
    }
  }

  const loginFunction = async (token, values) => {
    try {
      const decodedToken = jwt_decode(token)
      if (decodedToken) {
        const decodedTokenObj = {
          decoded_companylevel: decodedToken.company_level,
          decoded_companystatus: decodedToken.company_status,
          decoded_companyid: decodedToken.company_id,
        }
        localStorage.setItem('email', values.email)
        saveToken(token)
        dispatch(
          setIsMaster(decodedToken.company_level === COMPANY_LEVELS.master)
        )
        localStorage.setItem('companyname', decodedToken.company_name)
        redirectionFunction(decodedTokenObj, navigate, toast)
      }
    } catch (error) {
      appInsights.trackException({ exception: new Error(error.message) })
      toast.showToast(
        normalizeApiResponse(error)?.message,
        'error'
      )
    }
  }

  return (
    <>
      <div className="loginpage_main_container">
        <div className="login_container">
          <Formik
            initialValues={{
              ...initialValues,
            }}
            validationSchema={FormValidation}
            onSubmit={(values) => {
              accountLogin(values)
            }}
          >
            <Form>
              <div className="textfield_container">
                <Grid container rowSpacing={{ lg: 3, md: 3, sm: 2, xs: 1 }}>
                  <Grid item md={12} sm={12} xs={12}>
                    <div className="logo_container">
                      <img
                        src={Logo}
                        width="250px"
                        style={{ objectFit: 'cover' }}
                      ></img>
                    </div>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <div className="microsoftsignin_container">
                      <Button
                        onClick={() => handleSSOLogin(instance)}
                        sx={{
                          boxShadow:
                            '0px 1px 3px rgba(50,104,185, 0.2), 0px 2px 4px rgba(50,104,185, 0.26), 0px 0px 2px rgba(50,104,185, 0.14)',
                          backgroundColor: 'transparent',
                          borderRadius: '6.5px',
                          border: '1px solid #e6e6e6',
                          width: '320px',
                          textTransform: 'none',
                          textAlign: 'left',
                          color: 'black',
                          fontWeight: '400',
                          background: '#FFFFFF',
                        }}
                        endIcon={
                          processingSSO && <CircularProgress size={20} />
                        }
                        disabled={processingSSO}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <img
                            width="20px"
                            height="20px"
                            style={{ objectFit: 'cover' }}
                            src={MSLogo}
                          ></img>
                          <StyledMS>
                            Sign in with your Microsoft 365 Account
                          </StyledMS>
                        </Box>
                      </Button>
                      <div className="lineDiv" />
                    </div>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <ReusableTextfield
                      variant="outlined"
                      size="small"
                      id="Email"
                      name="email"
                      required
                      InputProps={{
                        style: {
                          height: '32px',
                          width: '320px',
                          fontSize: '12px',
                        },
                      }}
                      FormHelperTextProps={{ style: { fontSize: '12px' } }}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <ReusableTextfield
                      size="small"
                      id="Password"
                      name="password"
                      required
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        style: {
                          height: '32px',
                          width: '320px',
                          fontSize: '12px',
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      FormHelperTextProps={{ style: { fontSize: '12px' } }}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Stack direction="row" spacing={28}>
                        <StyledSpan>
                          <span onClick={() => props.changePage(true)}>
                            Forgot Password?
                          </span>
                        </StyledSpan>

                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            borderRadius: '4px',
                            width: '110px',
                          }}
                        >
                          Sign In{' '}
                          {loading ? (
                            <>
                              {' '}
                              &nbsp;{' '}
                              <CircularProgress
                                size={20}
                                sx={{ color: '#fff' }}
                              />{' '}
                            </>
                          ) : null}{' '}
                        </Button>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  )
}

export default CommonLogin
