import Box from '@mui/material/Box'
import {
  Button,
  Grid,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material'
import * as React from 'react'
import {
  addcustomer,
  addreseller,
  addserviceprovider,
} from '../../../../api/post/postApi'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 230,
    },
  },
}

// router dom
import { useSearchParams } from 'react-router-dom'

// jwt
import jwt_decode from 'jwt-decode'
import {
  decodeToken,
  normalizeApiResponse,
  requiredLabel,
} from '../../../../helpers/HelperFunctions'
import { useCommonRedux } from '../../../../store/middlewares/CommonRedux'
import {
  setCustomerTrigger,
  setResellerTrigger,
  setServiceProviderTrigger,
} from '../../../../store/slice/homeSlice'
import { useToastContext } from '../../../../App'
import { StyledSmallTextField } from '../../../styles/styled-components/StyledFormFields'
import validator from 'validator'

export default function NewDomain({
  toggleDrawer,
  config,
  currentRole,
  parentid,
  updateAddedCompany,
}) {
  const { dispatch, state } = useCommonRedux()
  const { toast } = useToastContext()
  const token = localStorage.getItem('sd_cp.jwt:tkn')
  const [searchparams, setsearchparams] = useSearchParams()

  const [companyName, setcompanyname] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [emailHelperText, setEmailHelperText] = React.useState('')
  const [domainname, setdomainname] = React.useState('')

  const [companynameerror, setcompanyanmeerror] = React.useState('')
  const [companydomainerror, setcompanydomainerror] = React.useState('')

  const [customercompanyname, setcustomercompanyname] = React.useState('')
  const [customercompanyhelpertext, setcustomercompanyhelpertext] =
    React.useState('')

  const [resellercompanyname, setresellercompanyname] = React.useState('')
  const [resellerhelpertext, setresellerhelpertext] = React.useState('')
  const [loading, setloading] = React.useState(false)

  const addnewserviceprovider = () => {
    if (companyName != '') {
      setcompanyanmeerror('')
    } else {
      setcompanyanmeerror('company name is required')
    }

    if (domainname != '') {
      setcompanydomainerror('')
    } else {
      setcompanydomainerror('domain name is required')
    }

    if (companyName != '' && domainname != '') {
      proceedApi()
    }
  }

  const addnewresellerfunction = () => {
    if (resellercompanyname != '') {
      setresellerhelpertext('')
    } else {
      setresellerhelpertext('company name is required')
    }
    if (email != '') {
      setEmailHelperText('')
    } else {
      setEmailHelperText('email is required')
    }
    if (validator.isEmail(email)) {
      setEmailHelperText('')
    } else {
      setEmailHelperText('please enter valid email')
    }
    if (resellercompanyname && email && validator.isEmail(email))
      proceedresellerApi()
  }

  const addnewcustomerfunction = () => {
    if (customercompanyname != '') {
      setcustomercompanyhelpertext('')
    } else {
      setcustomercompanyhelpertext('company name is required')
    }
    if (email != '') {
      setEmailHelperText('')
    } else {
      setEmailHelperText('email is required')
    }
    if (validator.isEmail(email)) {
      setEmailHelperText('')
    } else {
      setEmailHelperText('please enter valid email')
    }
    if (customercompanyname && email && validator.isEmail(email))
      proceedcustomerApi()
  }

  const proceedcustomerApi = async () => {
    setloading(true)
    let parentid = searchparams.get('company_id')
    if (!parentid) {
      parentid = decodeToken(token).company_id
    }

    const responsedata = {
      parentId: parentid,
      companyName: customercompanyname,
      email,
    }
    try {
      const data = await addcustomer(responsedata, config)
      if (data) {
        setloading(false)
        toggleDrawer(false)
        toast.showToast('Company Created Successfully')
        updateAddedCompany(currentRole, data.data.result)
      }
      dispatch(setCustomerTrigger('triggered'))
    } catch (error) {
      setloading(false)
      toggleDrawer(false)
      toast.showToast(
        normalizeApiResponse(error)?.message,
        'error'
      )
    }
  }

  const proceedresellerApi = async () => {
    setloading(true)
    const parentid = searchparams.get('company_id')
    if (parentid == null) {
      directresllercreation()
    } else {
      const responsedata = {
        parentId: parentid,
        companyName: resellercompanyname,
        email,
      }
      try {
        const data = await addreseller(responsedata, config)
        if (data) {
          setloading(false)
          toggleDrawer(false)
          toast.showToast('Reseller is Added Successfully')
          updateAddedCompany(currentRole, data.data.result)
          dispatch(setResellerTrigger('triggered'))
        }
      } catch (error) {
        setloading(false)
        toggleDrawer(false)
        toast.showToast( normalizeApiResponse(error)?.message, 'error')
      }
    }
  }

  // adding reseller if sp is directly logged in
  const directresllercreation = async () => {
    const decodedToken = jwt_decode(token)
    const responsebody = {
      parentId: decodedToken.company_id,
      companyName: resellercompanyname,
    }
    try {
      const data = await addreseller(responsebody, config)
      setloading(false)
      toggleDrawer(false)
      toast.showToast('Direct Customer is Added Successfully')
      updateAddedCompany(currentRole, data.data.result)
      dispatch(setResellerTrigger('triggered'))
    } catch (error) {
      setloading(false)
      toggleDrawer(false)
      toast.showToast( normalizeApiResponse(error)?.message, 'error')
    }
  }

  const proceedApi = async () => {
    setloading(true)
    const responseBody = {
      companyName: companyName,
      subDomain: domainname,
    }
    try {
      const data = await addserviceprovider(responseBody, config)
      if (data) {
        setloading(false)
        toggleDrawer(false)
        toast.showToast('Service Provider is Added Successfully')
        updateAddedCompany(currentRole, data.data.result)
        dispatch(setServiceProviderTrigger('triggered'))
      }
    } catch (error) {
      setloading(false)
      toggleDrawer(false)
      toast.showToast( normalizeApiResponse(error)?.message, 'error')
    }
  }

  return (
    <>
      <Box role="presentation">
        <div
          style={{
            display: 'flex',
            width: '100%',

            justifyContent: 'space-between',
            padding: '5px 10px 0 20px',
          }}
        >
          <Grid container columnSpacing={4} rowSpacing={3}>
            {currentRole == 'serviceprovider' ? (
              <>
                {' '}
                <Grid item md={12} sm={12} xs={12}>
                  {requiredLabel('Company Name')}
                  <StyledSmallTextField
                    onChange={(e) => setcompanyname(e.target.value)}
                    helperText={companynameerror}
                    error={companynameerror != '' ? true : false}
                    fullWidth
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography variant="label">Domain</Typography>
                  <StyledSmallTextField
                    helperText={companydomainerror}
                    error={companydomainerror != '' ? true : false}
                    onChange={(e) => setdomainname(e.target.value)}
                    fullWidth
                  />
                </Grid>{' '}
              </>
            ) : currentRole == 'reseller' ? (
              <>
                <Grid item md={12} sm={12} xs={12}>
                  {requiredLabel('Company Name')}
                  <StyledSmallTextField
                    onChange={(e) => setresellercompanyname(e.target.value)}
                    helperText={resellerhelpertext}
                    error={resellerhelpertext != '' ? true : false}
                    fullWidth
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  {requiredLabel('Email')}
                  <StyledSmallTextField
                    onChange={(e) => setEmail(e.target.value)}
                    helperText={emailHelperText}
                    error={emailHelperText}
                    type="email"
                    value={email}
                    fullWidth
                  />
                </Grid>
              </>
            ) : ['customer', 'directcustomer'].includes(currentRole) ? (
              <>
                <Grid item md={12} sm={12} xs={12}>
                  {requiredLabel('Company Name')}
                  <StyledSmallTextField
                    onChange={(e) => setcustomercompanyname(e.target.value)}
                    helperText={customercompanyhelpertext}
                    error={customercompanyhelpertext != '' ? true : false}
                    fullWidth
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  {requiredLabel('Email')}
                  <StyledSmallTextField
                    onChange={(e) => setEmail(e.target.value)}
                    helperText={emailHelperText}
                    error={emailHelperText}
                    type="email"
                    value={email}
                    fullWidth
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </div>

        <Box
          style={{
            //position: 'fixed',
            bottom: '10px',
            marginLeft: '20px',
            marginTop: '10px',
            background: '#fff',
            display: 'inline-flex',
            justifyContent: 'right',
          }}
        >
          <div>
            {currentRole == 'serviceprovider' ? (
              <Button
                onClick={addnewserviceprovider}
                variant="contained"
                color="primary"
                disableElevation
                size="large"
              >
                Add New Service Provider &emsp;{' '}
                {loading ? (
                  <CircularProgress sx={{ color: '#fff' }} size={20} />
                ) : null}
              </Button>
            ) : currentRole == 'reseller' ? (
              <Button
                onClick={addnewresellerfunction}
                variant="contained"
                color="primary"
                disableElevation
                size="large"
              >
                Add New Reseller &emsp;{' '}
                {loading ? (
                  <CircularProgress sx={{ color: '#fff' }} size={20} />
                ) : null}
              </Button>
            ) : ['customer', 'directcustomer'].includes(currentRole) ? (
              <Button
                onClick={addnewcustomerfunction}
                variant="contained"
                color="primary"
                disableElevation
                size="large"
              >
                Add New Customer &emsp;{' '}
                {loading ? (
                  <CircularProgress sx={{ color: '#fff' }} size={20} />
                ) : null}
              </Button>
            ) : null}
          </div>
        </Box>
      </Box>
    </>
  )
}
