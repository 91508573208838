

export const ERROR_MESSAGES = {
    PASSWORD_LENGTH_ERROR: 'Should be atleast 8 characters long',
    PASSWORD_PATTERN_ERROR:
        'Must contain one uppercase, one lowercase, one number',
    PASSWORD_PATTERN_SPECIAL_CHARACTERS: 'Must contain one special character',
    PASSWORD_DIFFERENT_ERROR : 'Must be different from old password',
    CONFIRM_PASSWORD_ERROR: 'Password should be the same',
    PASSWORD_VALIDATION_ERROR: 'Must contain atleast 8 characters, one uppercase, one lowercase, one special character, & one number',
}
