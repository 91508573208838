import React from 'react'
import { TextField, Box } from '@mui/material'
import { useField } from 'formik'
import { styled } from '@mui/system'

const StyledLabel = styled('span')(({ theme }) => ({
  color: theme.typography.Label.color,
  fontSize: theme.typography.Label.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  textAlign: 'left',
  fontStyle: 'normal',
  letterSpacing: '-0.1px',
  marginBottom: '4px',
  marginTop: '4px',
}))

const StyledAsterisk = styled('span')({
  color: 'red',
})

const StyledTextField = styled(TextField)(({ error }) => ({
  ...(error && {
    borderColor: '#979797',
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
    },
  }),
}))

const ReusableTextfield = ({ name, ...Properties }) => {
  const [field, meta] = useField(name)
  const configTextfield = {
    ...field,
    ...Properties,
    fullWidth: true,
    variant: 'outlined',
  }

  if (meta.touched && meta.error) {
    configTextfield.error = true
    configTextfield.helperText = meta.error
  } else if (name === 'password') {
    configTextfield.helperText = ''
  }

  if (configTextfield.value === 0) {
    configTextfield.value = ''
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <StyledLabel>
        {Properties.id}
        &nbsp;
        {Properties.required ? <StyledAsterisk>*</StyledAsterisk> : ''}
      </StyledLabel>
      <StyledTextField
        {...configTextfield}
        error={meta.touched && meta.error}
      />
    </Box>
  )
}

export default ReusableTextfield
