import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import React, { useState } from 'react'
import { useFormik } from 'formik'
import { useToastContext } from '../../../../App'
import jwt_decode from 'jwt-decode'
import { ChangePasswordValidationSchema } from '../../../../validations/SettingsValidation'
import { changePassword } from '../../../../api/post/postApi'
import { useCommonRedux } from '../../../../store/middlewares/CommonRedux'
import { setIsChangePasswordOpen } from '../../../../store/slice/commonSlice'
import { useNavigate } from 'react-router-dom'
import { normalizeApiResponse } from '../../../../helpers/HelperFunctions'
import PasswordValidator from '../../custom/password-validator/PasswordValidator'

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
  oldPassword: '',
}

const ChangePassword = () => {
  const { dispatch, state } = useCommonRedux()
  const [buttonLoader, setButtonLoader] = React.useState(false)
  const { toast } = useToastContext()
  const navigate = useNavigate()

  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  var localToken = localStorage.getItem('sd_cp.jwt:tkn')
  var config = { headers: { Authorization: `Bearer ${localToken}` } }

  //formik
  const formikStates = {
    initialValues: initialValues,
    onSubmit: (values) => handleSubmitChangePassword(values),
    validationSchema: ChangePasswordValidationSchema,
  }
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    resetForm,
    setFieldValue,
    setFieldError,
    isValid,
    isSubmitting,
  } = useFormik(formikStates)

  //submit
  const handleSubmitChangePassword = async (formFieldValues) => {
    try {
      proceedChangingPassword()
    } catch (error) {
      dispatch(setIsChangePasswordOpen(false))
      setButtonLoader(false)
      toast.showToast(normalizeApiResponse(error)?.message, 'error')
    }
  }

  const proceedChangingPassword = async () => {
    const decodedToken = jwt_decode(localToken)
    const userId = decodedToken.user_id
    const passwordData = {
      userId: userId,
      oldPassword: values.currentPassword,
      newPassword: values.newPassword,
    }
    try {
      setButtonLoader(true)
      const data = await changePassword(passwordData, config)
      if (data) {
        toast.showToast(
          'Password Changed Successfully. Please login with new password'
        )
        dispatch(setIsChangePasswordOpen(false))
        setTimeout(() => {
          setButtonLoader(false)
          resetForm({ values: initialValues })
          navigate('/login', { replace: true })
        }, 1000)
      }
    } catch (error) {
      setButtonLoader(false)
      dispatch(setIsChangePasswordOpen(true))
      toast.showToast(normalizeApiResponse(error)?.message || 'Please Verify your Old Password', 'error')
    }
  }

  return (
    <Dialog open={state.isChangePasswordOpen}>
      <DialogTitle>Change Password</DialogTitle>
      <form onSubmit={handleSubmit}>
        <Box position="absolute" top={0} right={0}>
          <Tooltip title="Close">
            <IconButton
              onClick={() => dispatch(setIsChangePasswordOpen(false))}
            >
              <CancelIcon color="error" size="large"></CancelIcon>
            </IconButton>
          </Tooltip>
        </Box>
        <DialogContent>
          <Grid container rowSpacing={1} columnSpacing={0}>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="label">Current Password</Typography>
              <TextField
                type={showCurrentPassword ? 'text' : 'password'}
                InputProps={{
                  style: {
                    height: '35px',
                  },
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      position="end"
                    >
                      {showCurrentPassword ? (
                        <VisibilityIcon
                          onClick={() =>
                            setShowCurrentPassword(!showCurrentPassword)
                          }
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={() =>
                            setShowCurrentPassword(!showCurrentPassword)
                          }
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                error={Boolean(errors.currentPassword)}
                helperText={errors.currentPassword}
                onChange={(e) =>
                  setFieldValue('currentPassword', e.target.value)
                }
                fullWidth
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="label">New Password</Typography>
              <TextField
                type={showNewPassword ? 'text' : 'password'}
                InputProps={{
                  style: {
                    height: '35px',
                  },
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      position="end"
                    >
                      {showNewPassword ? (
                        <VisibilityIcon
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                error={Boolean(errors.newPassword)}
                onChange={(e) => setFieldValue('newPassword', e.target.value)}
                fullWidth
              />

              {/* password validators */}

              <PasswordValidator
                currentPassword={ values.currentPassword }
                password={values.newPassword}
                isTouched={touched.newPassword || errors.newPassword}
                isSubmit={isSubmitting}
              />

            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="label">Confirm Password</Typography>
              <TextField
                type={showConfirmPassword ? 'text' : 'password'}
                InputProps={{
                  style: {
                    height: '35px',
                  },
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      position="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityIcon
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                error={Boolean(errors.confirmPassword)}
                helperText={errors.confirmPassword}
                onChange={(e) =>
                  setFieldValue('confirmPassword', e.target.value)
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" justifyContent="center">
            <Button variant="contained" type="submit" disabled={!isValid}>
              Update Password
              {buttonLoader ? (
                <>
                  {' '}
                  &nbsp; <CircularProgress
                    size={20}
                    sx={{ color: '#fff' }}
                  />{' '}
                </>
              ) : null}
            </Button>{' '}
            &emsp;
            <Button
              variant="outlined"
              onClick={() => dispatch(setIsChangePasswordOpen(false))}
            >
              cancel
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ChangePassword
