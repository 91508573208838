import React, { useEffect } from 'react'

// import mui
import { Menu, MenuItem, Avatar, Tooltip, Button } from '@mui/material'
import { useMsal } from '@azure/msal-react'
import { msalConfig } from '../../../../helpers/AuthConfiguration'
import ConfirmationModal from '../../custom/modal/modal'
import { ExpandMore } from '@mui/icons-material'
import { getProfile } from '../../../../api/get/getApi'
import jwt_decode from 'jwt-decode'
import ProfileSettings from './ProfileSettings'
import ChangePassword from './ChangePassword'
import { useCommonRedux } from '../../../../store/middlewares/CommonRedux'
import {
  setIsChangePasswordOpen,
  setIsMaster,
  setIsProfileSettingsOpen,
  setLoggedInUser,
  setPage,
} from '../../../../store/slice/commonSlice'
import { stringAvatar } from '../../../../helpers/HelperFunctions'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import SettingsIcon from '@mui/icons-material/Settings'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import LogoutIcon from '@mui/icons-material/Logout'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import PeopleIcon from '@mui/icons-material/People'
import KeyIcon from '@mui/icons-material/Key'
import { FlexBox } from '../../../styles/styled-components/StyledContainers'
import { PAGES } from '../../../../helpers/Constants'
import { render } from '@testing-library/react'
import Home from '../../../../pages/home/Home'
import UserManagement from '../../../../pages/userManagement/UserManagement'
import Billing from '../../../../pages/billing/Billing'
import Settings from '../../../../pages/settings/Settings'

export default function ProfileMenu() {
  const { instance } = useMsal()
  const { dispatch, state } = useCommonRedux()
  const logoutRequest = {
    account: null,
    redirectUri: msalConfig.auth.redirectUri, //defaults to application start page
    postLogoutRedirectUri: msalConfig.auth.redirectUri,
  }
  const clear = () => {
    localStorage.clear()
    sessionStorage.clear()
    location.href = `${location.origin}/login`
  }

  const [isLogoutModal, setLogoutModal] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    getProfileDetails()
  }, [])

  const getProfileDetails = async () => {
    const localToken = localStorage.getItem('sd_cp.jwt:tkn')
    const config = { headers: { Authorization: `Bearer ${localToken}` } }
    const decodedToken = jwt_decode(localToken)
    const userId = decodedToken.user_id
    const profileResponse = await getProfile(userId, config)
    dispatch(setLoggedInUser(profileResponse.data.result))
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setLogoutModal(false)
  }

  const logoutFunction = () => {
    const ssoHomeID = localStorage.getItem('ssoHomeID')
    setLogoutModal(false)
    dispatch(setIsMaster(false))
    if (ssoHomeID) {
      logoutRequest.account = instance.getAccountByHomeId(ssoHomeID)
      clear()

      instance.logoutRedirect(logoutRequest).then(() => {
        clear()
      })
    } else {
      clear()
    }
  }

  const openprofileFnc = (e) => {
    dispatch(setIsProfileSettingsOpen(true))
    setAnchorEl(null)
  }

  const openchangepasswordfunc = () => {
    dispatch(setIsChangePasswordOpen(true))
    setAnchorEl(null)
  }

  const profileItems = [
    // {
    //   name: PAGES.Home,
    //   icon: <HomeRoundedIcon color="primary" />,
    //   navFunction: () => dispatch(setPage(PAGES.Home)),
    // },
    // {
    //   name: 'User & Role Management',
    //   icon: <PeopleIcon color="primary" />,
    //   navFunction: () => dispatch(setPage(PAGES.UserAndRoleManagement)),
    // },
    // {
    //   name: 'Billing',
    //   icon: <AttachMoneyIcon color="primary" />,
    //   navFunction: () => dispatch(setPage(PAGES.Billing)),
    // },
    // {
    //   name: 'Settings',
    //   icon: <SettingsIcon color="primary" />,
    //   navFunction: () => dispatch(setPage(PAGES.Settings)),
    // },
    {
      name: 'Profile Settings',
      icon: <ManageAccountsIcon color="primary" />,
      navFunction: openprofileFnc,
    },
    {
      name: 'Change Password',
      icon: <KeyIcon color="primary" />,
      navFunction: openchangepasswordfunc,
    },
    {
      name: 'Logout',
      icon: <LogoutIcon color="primary" />,
      navFunction: () => setLogoutModal(true),
    },
  ]

  return (
    <div>
      <div>
        <Tooltip title="Profile">
          <Button
            style={{
              display: 'inline-flex',
              textAlign: 'left',
              color: '#2E384D',
              marginTop: '14px',
              padding: '0px',
            }}
            onClick={handleClick}
            endIcon={<ExpandMore style={{ marginLeft: '-5px' }} />}
          >
            <Avatar
              id="basic-button"
              sx={{ width: '28px', height: '28px' }}
              {...stringAvatar(
                `${state.loggedInUser?.firstName} ${state.loggedInUser?.lastName}`
              )}
            ></Avatar>
            <span
              style={{
                fontSize: '15px',
                marginLeft: '5px',
                fontWeight: 600,
              }}
            >
              {state.loggedInUser?.firstName} {state.loggedInUser?.lastName}
            </span>
          </Button>
        </Tooltip>
      </div>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {profileItems.map((item, index) => (
          <MenuItem key={item.name} onClick={item.navFunction}>
            <FlexBox gap={2}>
              {item.icon}
              {item.name}
            </FlexBox>
          </MenuItem>
        ))}
      </Menu>

      {state.isProfileSettingsOpen && <ProfileSettings />}
      {state.isChangePasswordOpen && <ChangePassword />}

      <ConfirmationModal
        handleConfirm={logoutFunction}
        title={<>Logout Confirmation</>}
        content={`Are you sure you want to log out ?`}
        isOpen={isLogoutModal}
        btnOk={'Confirm'}
        btnCancel={'Cancel'}
        handleClose={handleClose}
      />
    </div>
  )
}
