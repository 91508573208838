import { externalHttpSIPAnywhereBase } from '../utils/httpBaseUtil'
import { store } from '../utils/httpUtil'

//sip-trunk:ordered
export const getAllSipAnyWhereTrunkList = (customerId, sipAddressId) =>
  externalHttpSIPAnywhereBase(customerId).get(
    `SipTrunk/all?CompanyId=${customerId}&sipAddressId=${sipAddressId || 0}`
  )

export const api_getSipExistAddress = (customerId) =>
  externalHttpSIPAnywhereBase(customerId).get(
    `SipTrunk/GetSipAddress?companyId=${customerId}`
  )

export const api_getSipExistAvailableAddress = (customerId) =>
  externalHttpSIPAnywhereBase(customerId).get(
    `SipTrunk/existingAvailableAddress?companyId=${customerId}`
  )

export const api_CreateSipTrunkNew = (customerId, params) =>
  externalHttpSIPAnywhereBase(customerId).post(`SipTrunk`, params)

export const api_CreateSipTrunkNewSubmit = (customerId, params) =>
  externalHttpSIPAnywhereBase(customerId).post(`SipTrunk/submit`, params)

export const api_CreateSipServiceAddress = (customerId, params) =>
  externalHttpSIPAnywhereBase(customerId).post(`SipTrunk/UpsertAddress`, params)

export const api_getTeamsBundleConfigured = (customerId, sipAddressId) =>
  externalHttpSIPAnywhereBase(customerId).get(
    `TeamsBundleInfo/configured?companyId=${customerId}&sipAddressId=${sipAddressId || 0
    }`
  )

//sip: bundle
export const getAllSIPOrderTeamBundles = (customerId) =>
  externalHttpSIPAnywhereBase(customerId).get(`bundle/all`)

export const api_GetAllBundlePackages = (customerId) =>
  externalHttpSIPAnywhereBase(customerId).get(`Bundle/allPackages`)

//sip:order
export const api_GetSIPOrderSummary = (customerId, sipAddressId) =>
  externalHttpSIPAnywhereBase(customerId).get(
    `order/summary?companyId=${customerId}&orderId=0&sipAddressId=${sipAddressId || 0
    }`
  )

export const api_GetOrderGeneral = (customerId) =>
  externalHttpSIPAnywhereBase(customerId).get(
    `Order/general?companyId=${customerId}`
  )

export const api_OrderSIPTeamsBundle = (customerId, params) =>
  externalHttpSIPAnywhereBase(customerId).post(`order`, params)

export const api_NewOrderSipTrunk = (customerId, sipAddressId) =>
  externalHttpSIPAnywhereBase(customerId).post(
    `order/submit?sipAddressId=${sipAddressId || 0}`
  )

export const api_getAllSIPOrders = (customerId, orderTypeId, sipAddressId) =>
  externalHttpSIPAnywhereBase(customerId).get(
    `Order/all?CompanyId=${customerId}&sipAddressId=${sipAddressId}`
  )

export const api_SuspendOrder = (customerId, params) =>
  externalHttpSIPAnywhereBase(customerId).put(`Order/suspend`, params)

export const api_ReinStatedOrder = (customerId, params) =>
  externalHttpSIPAnywhereBase(customerId).put(`Order/reinstate`, params)

export const api_getOrderAddress = (customerId) =>
  externalHttpSIPAnywhereBase(customerId).get(
    `Order/address?companyId=${customerId}`
  )

//sip:didNumber
export const api_getDIDNumber = (customerId, sipAddressId) =>
  externalHttpSIPAnywhereBase(customerId).get(
    `DidNumber?CompanyId=${customerId}&sipAddressId=${sipAddressId || 0}`
  )
export const api_getDidNumberE911Override = (customerId) =>
  externalHttpSIPAnywhereBase(customerId).get(`DidNumber/e911override`)
export const api_getAvailableDIDNumbers = (
  customerId,
  { state, areaCode, rateCenter }
) =>
  externalHttpSIPAnywhereBase(customerId).get(
    `DidNumber/available/dids?state=${state}&areaCode=${areaCode}&rateCenter=${rateCenter}`
  )
export const api_getAvailableTollFreeNumbers = (customerId, { npa }) =>
  externalHttpSIPAnywhereBase(customerId).get(
    `DidNumber/available/tollfrees?npa=${npa}`
  )
export const api_getDIDFeatures = (customerId, { number }) =>
  externalHttpSIPAnywhereBase(customerId).get(
    `DidNumber/features?number=${number}`
  )
export const api_patchDIDNumber = (customerId, number, params) =>
  externalHttpSIPAnywhereBase(customerId, true).patch(
    `DidNumber/did?Number=${number}`,
    params
  )
export const api_patchDIDTollFreeNumber = (customerId, number, params) =>
  externalHttpSIPAnywhereBase(customerId, true).patch(
    `DidNumber/tollfree?Number=${number}`,
    params
  )

export const api_patchDIDCallForwardBackupRemove = (
  body,
  number,
  customerId
) =>
  externalHttpSIPAnywhereBase(customerId, true).patch(
    `DidNumber/RemoveCallForwardingOrBackUp?Number=${number}`,
    body
  )
//sip:ratecenter
export const api_getRateCenterByAreaCode = (customerId, { areaCode }) =>
  externalHttpSIPAnywhereBase(customerId).get(`ratecenter?areaCode=${areaCode}`)
export const api_getRateCenterStateByAreaCode = (customerId, areaCode) =>
  externalHttpSIPAnywhereBase(customerId).get(
    `ratecenter/statebyareacode?areaCode=${areaCode}`
  )
export const api_getRateCenterAllState = (customerId) =>
  externalHttpSIPAnywhereBase(customerId).get(`ratecenter/allstate`)

//sip:newNumberRequest
export const api_getNewNumberRequest = (customerId, orderId) =>
  externalHttpSIPAnywhereBase(customerId).get(
    `NewNumberRequest?orderId=${orderId}`
  )
export const api_createNewNumberRequest = (customerId, params) =>
  externalHttpSIPAnywhereBase(customerId).post(`NewNumberRequest`, params)

//sip:E911
export const api_createE911 = (customerId, params) =>
  externalHttpSIPAnywhereBase(customerId).post(`e911`, params)
export const api_updateE911 = (customerId, params) =>
  externalHttpSIPAnywhereBase(customerId).put(`e911`, params)
export const api_deleteE911 = (customerId, number,params) =>
  externalHttpSIPAnywhereBase(customerId).delete(`e911/${number}`, params)


//sip: porting
export const api_getAllPorting = (companyId, sipAddressId) =>
  externalHttpSIPAnywhereBase(companyId).get(
    `Porting/all?companyId=${companyId}&sipAddressId=${sipAddressId}`
  )

export const api_getPortingDID = (companyId, orderId) =>
  externalHttpSIPAnywhereBase(companyId).get(`Porting/did?orderId=${orderId}`)

export const api_getPortingTollFree = (companyId, orderId) =>
  externalHttpSIPAnywhereBase(companyId).get(
    `Porting/tollfree?orderId=${orderId}`
  )

export const api_getPortingWaiting = (companyId) =>
  externalHttpSIPAnywhereBase(companyId).get(
    `Porting/waiting?CompanyId=${companyId}`
  )

export const api_getPortingPortDate = (companyId, date) =>
  externalHttpSIPAnywhereBase(companyId).get(`Porting/portdate?date=${date}`)

export const api_createPortingPortingDID = (companyId, params) =>
  externalHttpSIPAnywhereBase(companyId).post(`Porting/did`, params)

export const api_createPortingPortingTollFree = (companyId, params) =>
  externalHttpSIPAnywhereBase(companyId).post(`Porting/tollfree`, params)

export const api_PortingDIDUploadForms = (companyId, { did }, params) =>
  externalHttpSIPAnywhereBase(companyId).post(
    `Porting/uploadforms/did?DidPortingInfoId=${did}`,
    params
  )

export const api_PortingTollFreeUploadForms = (
  companyId,
  { tollFree },
  params
) =>
  externalHttpSIPAnywhereBase(companyId).post(
    `Porting/uploadforms/tollfree?TollFreePortingInfoId=${tollFree}`,
    params
  )

export const api_PortingSubmitWaiting = (companyId, params) =>
  externalHttpSIPAnywhereBase(companyId).post(`Porting/submitwaiting`, params)

export const api_PortingDIDCancel = (companyId, params) =>
  externalHttpSIPAnywhereBase(companyId).post(`Porting/cancel/did`, params)

export const api_PortingTollFreeCancel = (companyId, params) =>
  externalHttpSIPAnywhereBase(companyId).post(`Porting/cancel/tollfree`, params)

export const api_PortingValidate = (companyId, params) =>
  externalHttpSIPAnywhereBase(companyId).post(
    `Porting/validateForPorting`,
    params
  )

//sip:featureChangeInfo
export const api_getFeatureChangeInfo = (companyId, orderId) =>
  externalHttpSIPAnywhereBase(companyId).get(
    `FeatureChangeInfo?orderId=${orderId}`
  )

//sip: removeNumber
export const api_getRemoveNumber = (companyId, orderId) =>
  externalHttpSIPAnywhereBase(companyId).get(`RemoveNumber?orderId=${orderId}`)

export const api_postRemoveNumber = (companyId, params) =>
  externalHttpSIPAnywhereBase(companyId).post(`RemoveNumber`, params)

//configuration
export const api_postSipMaxLocation = (params) =>
  store(`Customer/product/MaxLocation`, params)
