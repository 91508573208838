import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import React, { useEffect } from 'react'

const ConfirmationModal = ({
  title,
  content,
  children,
  size = 'sm',
  hiddenConform,
  hiddenCancel,
  isOpen,
  handleClose,
  handleConfirm,
  hideActionsBtn,
  topValue,
  btnOk,
  btnCancel,
  okLabel = 'Confirm',
  color = 'error',
}) => {
  const [confirmBtn, setConfirmBtn] = React.useState(okLabel)
  const [cancelBtn, setCancelBtn] = React.useState('Cancel')
  useEffect(() => {
    if (btnCancel) {
      setCancelBtn(btnCancel)
    }
    if (btnOk) {
      setConfirmBtn(btnOk)
    }
  }, [btnOk])

  return (
    <Dialog open={isOpen} maxWidth={size} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <Tooltip title="Close">
          <IconButton onClick={handleClose}>
            <CancelIcon color="error" size="large"></CancelIcon>
          </IconButton>
        </Tooltip>
      </Box>
      <DialogContent>
        <div>{content}</div>
      </DialogContent>
      {hideActionsBtn ? (
        ''
      ) : (
        <DialogActions>
          {!hiddenCancel && (
            <Button color="secondary" onClick={handleClose} variant="contained">
              {cancelBtn}
            </Button>
          )}

          {hiddenConform ? (
            ''
          ) : (
            <Button color={color} onClick={handleConfirm} variant="contained">
              {confirmBtn}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default ConfirmationModal
