// Set variables from .env file references
const geturl = window.location.origin
const clientId = process.env.REACT_APP_MSAL_KEY
// const tenantId = process.env.REACT_APP_TENANTID

export const msalConfig = {
  auth: {
    clientId: `${clientId}`,
    authority: `https://login.microsoftonline.com/organizations`,
    knownAuthorities: [],
    redirectUri: `${geturl}/login`,
    postLogoutRedirectUri: `${geturl}/login`,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
  prompt: 'select_account',
}

// Add the endpoints hereyfor Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
}