import ManageAccountsSharpIcon from '@mui/icons-material/ManageAccountsSharp'

//ENV URLs

export const ENV_URLS = {
  API: process.env.REACT_APP_API,
  URL: process.env.REACT_APP_ROOT_URL,
  SERVICE_CI_URL: process.env.REACT_APP_SERVICE_CI_URL,
  SERVICE_CAC_URL: process.env.REACT_APP_SERVICE_CAC_URL,
  SERVICE_COREVIEW_URL: process.env.REACT_APP_SERVICE_COREVIEW_URL,
  SERVICE_MAXCS_URL: process.env.REACT_APP_SERVICE_MAXCS_URL,
  SERVICE_DIRECTROUTING_URL: process.env.REACT_APP_SERVICE_DIRECTROUTING_URL,
  SERVICE_SIP_TRUNKING_URL: process.env.REACT_APP_SERVICE_SIPTRUNKING_URL,
  MSAL_KEY: process.env.REACT_APP_MSAL_KEY,
  MSAL_TENANTID: process.env.REACT_APP_TENANTID,
  TOKEN_IDENTIFIER: process.env.REACT_APP_TOKEN_IDENTIFIER,
  APPINSIGHTS_CONNECTION_STRING: process.env.REACT_APP_APPINSIGHTS_KEY,
  TDR_AUTOPROVISION_FUNCTION:
    process.env.REACT_APP_TDR_AUTOPROVISION_FUNCTION_URL,
  TDR_AUTOPROVISIONING_FUNCTION_KEY:
    process.env.REACT_APP_TDR_AUTOPROVISIONING_FUNCTION_KEY,
}

export const ERROR_STATUS_CODES = {
  CORS: 301,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
}

export const PAGES = {
  Home: 'Home',
  UserAndRoleManagement: 'User & Role Management',
  Billing: 'Billing',
  Settings: 'Settings',
  ProfileSettings: 'Profile Settings',
  ChangePassword: 'Change Password',
  Logout: 'Logout',
  Login: 'Login',
}

export const CONTACT_TYPE_ENUM = {
  Primary: 1,
  Billing: 2,
  Technical: 3,
  Purchasing: 4,
  Sales: 5,
}

// Order Status
// export const ORDER_STATUS_ENUM = { //OLD
//   Debug: 0,
//   Cancelled: 1,
//   Suspend: 2,
//   Drafting: 4,
//   Pending: 8,
//   InProgress: 16,
//   Active: 32,
// }

export const ORDER_STATUS_ENUM = {
  //NEW
  Draft: 1,
  InProgress: 2,
  Pending: 3,
  Active: 4,
  Completed: 5,
  PendingCancellation: 6,
  ETFInReview: 7,
  CancellationApproved: 8,
  Cancelled: 9,
}

export const NUMBER_STATUS_ENUM = {
  Debug: 0,
  Active: 1,
  Disabled: 2,
  Holding: 4,
  PortPending: 8,
}

export const COMPANY_LEVELS = {
  master: 1,
  serviceprovider: 2,
  reseller: 3,
  customer: 4,
  salesRep: 5,
}

export const COMPANY_STATUS = {
  Pending: 0,
  Draft: 1,
  ApprovalPending: 2,
  Rejected: 3,
  Active: 4,
  Suspend: 5,
  Deleted: 6,
}

export const COMPANY_STATUS_OBJ = [
  {
    name: 'Pending',
    value: 0,
    color: '#fc8403',
  },
  {
    name: 'Draft',
    value: 1,
    color: '#929294',
  },
  {
    name: 'Approval Pending',
    value: 2,
    color: 'red',
  },
  {
    name: 'Rejected',
    value: 3,
    color: '#B00020', //'#515152',
  },
  {
    name: 'Active',
    value: 4,
    color: '#00c853',
  },
  // {
  //   name: 'Suspend',
  //   value: 5,
  //   color: '#0288d1',
  // },
  // {
  //   name: 'Deleted',
  //   value: 6,
  //   color: '#B00020',
  // },
]

//services
export const SERVICES = {
  UnCategorized: 0, // Not a service
  DirectRouting: 1,
  MaxCloudCoreView: 2,
  SipTrunking: 3,
}

export const SERVICE_PRODUCT_CODES = {
  DirectRouting: 'DR', // Not a service
  MaxCloudCoreView: 'CVUC',
  SipTrunking: 'SIPT',
}

export const PRODUCT_NAMES = {
  DR: 'Direct Routing',
  TPS: 'Teams Phone Solutions',
  MAXCS: 'MaxCS',
  MAXCSPC: 'MaxCS Private Cloud',
  SIPT: 'SIP Trunking',
  MAXCLOUD: 'MaxCloud UC',
}

export const SERVICE_NAMES = {
  [SERVICES.UnCategorized]: 'Uncategorized',
  [SERVICES.DirectRouting]: 'Teams Phone Solutions',
  [SERVICES.MaxCloudCoreView]: 'MaxCloud UC',
  [SERVICES.SipTrunking]: 'SIP Trunking',
}

export const PACKAGE_BUNDLE_TERMS = [1, 3]
export const SIP_ADDRESS_TABS = { New: 0, Existing: 1 }

// TAB ITEMS
export const SIP_TRUNK_TABS = {
  InitialOrder: ['General', 'SIP Trunk', 'Orders'],
  CompletedOrders: [
    'General',
    'SIP Trunk',
    'number Overview',
    'Porting',
    'Orders',
  ],
}

//PERMISSIONS

export const PERMISSIONS = {
  //dashboard permission
  masterDashboard: 'permission.master.dashboard.view',
  serviceproviderDashboard: 'permission.serviceprovider.dashboard.view',
  resellerDashboard: 'permission.reseller.dashboard.view',
  customerDashboard: 'permission.customer.dashboard.view',
  //master role permission
  masterRoleView: 'permission.master.role.view',
  masterRoleCreate: 'permission.master.role.create',
  masterRoleUpdate: 'permission.master.role.update',
  masterRoleDelete: 'permission.master.role.delete',
  // serviceprovider role permission
  serviceproviderRoleView: 'permission.serviceprovider.role.view',
  serviceproviderRoleCreate: 'permission.serviceprovider.role.create',
  serviceproviderRoleUpdate: 'permission.serviceprovider.role.update',
  serviceproviderRoleDelete: 'permission.serviceprovider.role.delete',
  // reseller role permission
  resellerRoleView: 'permission.reseller.role.view',
  resellerRoleCreate: 'permission.reseller.role.create',
  resellerRoleUpdate: 'permission.reseller.role.update',
  resellerRoleDelete: 'permission.reseller.role.delete',
  // customer role permission
  customerRoleView: 'permission.customer.role.view',
  customerRoleCreate: 'permission.customer.role.create',
  customerRoleUpdate: 'permission.customer.role.update',
  customerRoleDelete: 'permission.customer.role.delete',
  //master user permission
  masterUserView: 'permission.master.user.view',
  masterUserCreate: 'permission.master.user.create',
  masterUserUpdate: 'permission.master.user.update',
  masterUserDelete: 'permission.master.user.delete',
  // serviceprovider user permission
  serviceproviderUserView: 'permission.serviceprovider.user.view',
  serviceproviderUserCreate: 'permission.serviceprovider.user.create',
  serviceproviderUserUpdate: 'permission.serviceprovider.user.update',
  serviceproviderUserDelete: 'permission.serviceprovider.user.delete',
  // reseller user permission
  resellerUserView: 'permission.reseller.user.view',
  resellerUserCreate: 'permission.reseller.user.create',
  resellerUserUpdate: 'permission.reseller.user.update',
  resellerUserDelete: 'permission.reseller.user.delete',
  // customer user permission
  customerUserView: 'permission.customer.user.view',
  customerUserCreate: 'permission.customer.user.create',
  customerUserUpdate: 'permission.customer.user.update',
  customerUserDelete: 'permission.customer.user.delete',

  //master contact permission
  masterContactView: 'permission.master.contact.view',
  masterContactCreate: 'permission.master.contact.create',
  masterContactUpdate: 'permission.master.contact.update',
  masterContactDelete: 'permission.master.contact.delete',
  //serviceprovider contact permission
  serviceproviderContactView: 'permission.serviceprovider.contact.view',
  serviceproviderContactCreate: 'permission.serviceprovider.contact.create',
  serviceproviderContactUpdate: 'permission.serviceprovider.contact.update',
  serviceproviderContactDelete: 'permission.serviceprovider.contact.delete',
  //reseller contact permission
  resellerContactView: 'permission.reseller.contact.view',
  resellerContactCreate: 'permission.reseller.contact.create',
  resellerContactUpdate: 'permission.reseller.contact.update',
  resellerContactDelete: 'permission.reseller.contact.delete',
  //customer contact permission
  customerContactView: 'permission.reseller.contact.view',
  customerContactCreate: 'permission.reseller.contact.create',
  customerContactUpdate: 'permission.reseller.contact.update',
  customerContactDelete: 'permission.reseller.contact.delete',

  // master product permission
  masterProductView: 'permission.master.product.view',
  masterProductCreate: 'permission.master.product.create',
  masterProductUpdate: 'permission.master.product.update',
  masterProductDelete: 'permission.master.product.delete',
  // serviceprovider product permission
  serviceproviderProductView: 'permission.serviceprovider.product.view',
  serviceproviderProductCreate: 'permission.serviceprovider.product.create',
  serviceproviderProductUpdate: 'permission.serviceprovider.product.update',
  serviceproviderProductDelete: 'permission.serviceprovider.product.delete',
  // reseller product permission
  resellerProductView: 'permission.reseller.product.view',
  resellerProductCreate: 'permission.reseller.product.create',
  resellerProductUpdate: 'permission.reseller.product.update',
  resellerProductDelete: 'permission.reseller.product.delete',
  // customer product permission
  customerProductView: 'permission.customer.product.view',
  customerProductCreate: 'permission.customer.product.create',
  customerProductUpdate: 'permission.customer.product.update',
  customerProductDelete: 'permission.customer.product.delete',

  // master setting permission
  masterSettingView: 'permission.master.setting.view',
  masterSettingUpdate: 'permission.master.setting.update',
  // serviceprovider setting permission
  serviceproviderSettingView: 'permission.serviceprovider.setting.view',
  serviceproviderSettingUpdate: 'permission.serviceprovider.setting.update',
  // reseller setting permission
  resellerSettingView: 'permission.reseller.setting.view',
  resellerSettingUpdate: 'permission.reseller.setting.update',
  // customer setting permission
  customerSettingView: 'permission.customer.setting.view',
  customerSettingUpdate: 'permission.customer.setting.update',

  // master billing permission
  masterBillingView: 'permission.master.billing.view',
  masterBillingCreate: 'permission.master.billing.create',
  masterBillingUpdate: 'permission.master.billing.update',
  masterBillingDelete: 'permission.master.billing.delete',
  // serviceprovider billing permission
  serviceproviderBillingView: 'permission.serviceprovider.billing.view',
  serviceproviderBillingCreate: 'permission.serviceprovider.billing.create',
  serviceproviderBillingUpdate: 'permission.serviceprovider.billing.update',
  serviceproviderBillingDelete: 'permission.serviceprovider.billing.delete',
  // reseller billing permission
  resellerBillingView: 'permission.reseller.billing.view',
  resellerBillingCreate: 'permission.reseller.billing.create',
  resellerBillingUpdate: 'permission.reseller.billing.update',
  resellerBillingDelete: 'permission.reseller.billing.delete',
  // customer billing permission
  customerBillingView: 'permission.customer.billing.view',
  customerBillingCreate: 'permission.customer.billing.create',
  customerBillingUpdate: 'permission.customer.billing.update',
  customerBillingDelete: 'permission.customer.billing.delete',

  // serviceprovider company permission
  masterCompanyView: 'permission.master.company.view',
  masterCompanyCreate: 'permission.master.company.create',
  masterCompanyUpdate: 'permission.master.company.update',
  // serviceprovider company permission
  serviceproviderCompanyView: 'permission.serviceprovider.company.view',
  serviceproviderCompanyCreate: 'permission.serviceprovider.company.create',
  serviceproviderCompanyUpdate: 'permission.serviceprovider.company.update',
  // reseller company permission
  resellerCompanyView: 'permission.reseller.company.view',
  resellerCompanyCreate: 'permission.reseller.company.create',
  resellerCompanyUpdate: 'permission.reseller.company.update',
  // customer company permission
  customerCompanyView: 'permission.customer.company.view',
  customerCompanyCreate: 'permission.customer.company.create',
  customerCompanyUpdate: 'permission.customer.company.update',
  //notification permission
  masterNotificationView: 'permission.master.notification.view',
  masterNotificationCreate: 'permission.master.notification.create',
  masterNotificationUpdate: 'permission.master.notification.update',
  masterNotificationDelete: 'permission.master.notification.delete',
  // Sales Rep Approvals Permission
  salesRepServiceCancelApproval:
    'permission.salesrep.approvals.service.cancellation',
}

export const DATE_FORMATE = 'MMM DD, YYYY'
export const MASTER_NAVIGATION_PANEL_ITEMS = [
  'Home',
  'User & Role Management',
  'Billing',
  'Settings',
]
export const MASTER_PROFILE_SETTINGS = [
  'Profile Settings',
  'Change Password',
  'Logout',
]
export const MASTER_PROFILE_MENU = [
  ...MASTER_NAVIGATION_PANEL_ITEMS,
  ...MASTER_PROFILE_SETTINGS,
]

export const BREADCRUMB_MASTER = {
  name: 'Master',
  icon: <ManageAccountsSharpIcon fontSize="inherit" sx={{ mr: 0.2 }} />,
  href: '/master',
  isActive: false,
}
export const BREADCRUMB_SERVICEPROVIDER = {
  name: 'Service Provider',
  icon: '',
  isActive: false,
}
export const BREADCRUMB_RESELLER = {
  name: 'Reseller',
  icon: '',
  isActive: false,
}
export const BREADCRUMB_CUSTOMER = {
  name: 'Customer',
  icon: '',
  isActive: false,
}
export const BREADCRUMB_DIRECT_CUSTOMER = {
  name: 'Direct Customer',
  icon: '',
  isActive: false,
}
export const BREADCRUMB_REFERRAL_AGENT = {
  name: 'Referral Agent',
  icon: '',
  isActive: false,
}

export const PRODUCT_CODES = {
  sipTrunking: 'SIPT',
  maxCS: 'MAXCS',
  maxCloudUC: 'CVUC',
  directRouting: 'DR',
}

export const SERVICE_CANCELLATION_DESCRIPTIONS = {
  Saas: `  Cancellation of SaaS services will result in the MaxCS server
                                receiving no valid licenses from Altigen’s licensing server. It
                                could take an additional 24 hours from when the cancellation
                                order is placed until the server receives updated licensing
                                information.`,
  MaxCSPrivateCloud: ` The hosted MaxCS Cloud server will be powered down when the
                                cancellation is processed and will no longer be accessible.
                                Please make sure all desired files (AltiServ backups, call
                                recordings, etc.) have been removed from the server prior to
                                cancellation.`,
  SIPTrunking: `SIP Trunk services should not be cancelled until any pending
                                number (DID / Toll Free) port requests have been completed. Any
                                numbers that have not been ported when service is cancelled will
                                be permanently lost. Cancelling SIP Trunking services prior to
                                number porting may result in delays in the number port, or could
                                render the port impossible. You may be able to take, or “port,”
                                your current telephone number(s) to another service provider.
                                Your new service provider should be able to tell you when it is
                                safe to cancel your old service.`,
}

//PATTERNS
export const IP_PATTERN =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
export const IP_ADDRESS_PATTERN =
  '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
export const NETMASK_ITEMS = [32, 30, 29, 28, 27, 26, 25, 24]

//Password Patterns
export const PASSWORD_PATTERN =
  /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})/

//MODES
export const HOST_MODES = {
  IP: 'IP',
  FQDN: 'FQDN',
}
export const NUMBER_ASSIGNMENT_MODES = {
  SINGLE: 'UI Mode',
  BULK: 'CSV Mode',
}

//TOOTIP
export const TOOLTIP_TEXT = {
  CostText:
    'These prices are MSRP only before taxes, detailed taxes will be included in the final invoice',
  CallForwardingAndBackupText:
    'You can choose either Call Forwarding OR Call Backup. If you want to set up Call Backup, Call Forwarding needs to be disabled and vice-versa.',
}

// CONSTANT TOAST MESSAGES
export const TOAST_MESSAGES = {
  //fetch
  AcceptTermsAndCondition: 'Please accept terms and conditions',
  UnableToFetchData: 'Unable to fetch data',

  //success
  SubmittedServiceCancellation:
    'Service Cancellation request submitted successfully',
  DataSaved: 'Data saved successfully',
  OrderCreated: 'Order created successfully',
  OrderUpdated: 'Order updated successfully',
  NewNumberRequestSuccess: 'New number request submitted successfully',
  EmailRemoved: 'Email removed successfully',
  NumberDisconnected: 'Number disconnected successfully',
  CustomerDeleted: 'Customer Deleted Successfully',
  ResellerDeleted: 'Reseller Deleted Successfully',
  ServiceProviderDeleted: 'Service Provider Deleted Successfully',
  ServiceCancellationApproved: 'Service Cancellation Approved Successfully',
  ServiceCancellationRejected: 'Service Cancellation Rejected Successfully',
  //error
  UnableToFetchUserData: 'Unable to fetch user data',
  UnableToFetchOrderDetails: 'Unable to fetch order details',
  UnableToSaveData: 'Unable to save data',
  UnableToCreateOrder: 'Unable to create order',
  UnableToUpdateOrder: 'Unable to update order',
  UnableToDisconnectNumber: 'Unable to disconnect number',
  PleaseProvideValidData: 'Please provide valid data',
  SomethingWentWrong: 'Something went wrong',
  ProvideAtleastOneConfiguration: 'Please provide atleast one Configuration',
  SelectAleastOnePlan: 'Please select atleast one plan',
  ProblemInPdfBill: 'Problem in downloading pdf bill',
  ProblemWithDeletingCustomer: 'Problem with deleting customer',
  ProblemWithDeletingReseller: 'Problem with deleting reseller',
  UnableToApproveServiceCancellation: 'Unable to approve service cancellation',
  UnableToRejectServiceCancellation: 'Unable to reject service cancellation',
  UnAuthorisedAction: 'You dont have permission to perform this action',
  UnAuthorisedAccess: 'You dont have permission to access this page',
  SuccessfullSave: "Saved Successfully",
  SuccessRemoved: "Removed Successfully"
}

export const ADD_ON_CODES = {
  MAX_FAX: 'MaxFax',
  OTHERS: 'Others',
}

export const PRODUCT_CATEGORY_ENUM = {
  // 1 to 4 belongs to MaxUc service
  BaseIndividualUserLicenses: 1,
  AddOn: 2,
  DeploymentServices: 3,
  MonthlySupportServices: 4,
  TeamsDirectRoutingServices: 5,
  TeamsReportingServices: 6,
  TeamsCallRecordingServices: 7,
  AltigenAcademy: 8, // not needed in UI
  ProfessionalServices: 9, //not needed in UI
}

export const BILLING_FREGUENCY_ENUM = {
  OneTime: 1,
  Recurring: 2,
}

export const NUMBER_CATEGORY_ENUM = {
  UnCategory: 0,
  Primary: 1,
  Secondary: 2,
};

export const NUMBER_TYPE = {
  DID : 'DID',
  TollFree : 'Toll Free',
  Other : 'Other',
}

export const E911_TABS = {
  ExistingPrimaryAddress:0,
}

export const PRODUCT_CATEGORY_TEXT_ENUM = {
  [PRODUCT_CATEGORY_ENUM.BaseIndividualUserLicenses]:
    'Base + Individual Licenses',
  [PRODUCT_CATEGORY_ENUM.AddOn]: 'Add Ons',
  [PRODUCT_CATEGORY_ENUM.DeploymentServices]: 'Deployment Services',
  [PRODUCT_CATEGORY_ENUM.MonthlySupportServices]: 'Monthly Support Services',
  [PRODUCT_CATEGORY_ENUM.TeamsDirectRoutingServices]:
    'Teams Direct Routing Services',
  [PRODUCT_CATEGORY_ENUM.TeamsReportingServices]: 'Teams Reporting Services',
  [PRODUCT_CATEGORY_ENUM.TeamsCallRecordingServices]:
    'Teams Call Recording Services',
  [PRODUCT_CATEGORY_ENUM.AltigenAcademy]: 'Altigen Academy',
  [PRODUCT_CATEGORY_ENUM.ProfessionalServices]: 'Monthly Support Services',
}

// ------------ WIZARD  STEPS (STEPPERS) STARTS ---------------

//Signup steppers

export const SERVICE_PROVIDER_STEPS = [
  //service provider
  'Account details',
  'Company details',
  'Billing info',
  'Billing type',
  'Payment info',
  'Terms & Conditions',
  'Appearance',
]

export const CUSTOMER_STEPS = [
  'Account details',
  'Company details',
  'Terms & Conditions',
  'Appearance',
]

export const RESELLER_DIRECT_CUSTOMER_STEPS = [
  'Account details',
  'Company details',
  'Billing info',
  'Payment info',
  'Terms & Conditions',
  'Appearance',
]

export const DIRECT_CUSTOMER_SIP_STEPS = [
  //service provider
  'Account details',
  'Company details',
  'Billing info',
  // 'Billing type',
  'Payment info',
  'SIP Details',
  'Terms & Conditions',
  'Appearance',
]
export const CUSTOMER_SIP_STEPS = [
  //service provider
  'Account details',
  'Company details',
  'SIP Details',
  'Terms & Conditions',
  'Appearance',
]

// -- MAXCLOUD UC --
export const MAX_CLOUD_UC_INITIAL_ORDER_STEPS = ['Service Plan', 'Summary']
export const MAX_CLOUD_UC_ACTIVATED_ORDER_STEPS = [
  'Service Plan',
  'Porting (optional)',
  'New Numbers',
  'Summary',
]
export const TEAMS_PHONE_SOLUTIONS_INITIAL_ORDER_STEPS = [
  'Service Plan',
  'Summary',
]
export const TEAMS_PHONE_SOLUTIONS_ACTIVATED_ORDER_STEPS = [
  'Service Plan',
  'Porting (optional)',
  'New Numbers',
  'Summary',
]
export const TEAMS_PHONE_SOLUTIONS_ACTIVATED_ORDER_TABS = [
  'General',
  'Number Overview',
  'Number Assignment',
  'Porting',
  'Add-Ons',
  'Orders',
]
export const MAX_CLOUD_ACTIVATED_ORDER_TABS = [
  'General',
  'Number Overview',
  // 'Number Assignment',
  'Porting',
  'Add-Ons',
  'Orders',
]
export const MAX_CLOUD_ORDERS_WITHOUT_ADDONS_TABS = [
  'General',
  'Number Overview',
  'Porting',
  'Orders',
]
export const TPS_ORDERS_WITHOUT_ADDONS_TABS = [
  'General',
  'Number Overview',
  'Number Assignment',
  'Porting',
  'Orders',
]

// ------------ WIZARD  STEPS (STEPPERS) ENDS ---------------

//SERVICE AND REV PRODUCTS ORDERING

export const SERVICE_TYPES = {
  SIPTrunking: 19,
  MaxCloudUC: 27,
  DirectRouting: 20,
  DeploymentServices: 18,
  SupportedServices: 21,
}

export const BUNDLE_CATEGORIES = [
  {
    service: SERVICES.DirectRouting,
    categoryId: PRODUCT_CATEGORY_ENUM.TeamsDirectRoutingServices,
    order: 1,
  },
  {
    service: SERVICES.DirectRouting,
    categoryId: PRODUCT_CATEGORY_ENUM.TeamsReportingServices,
    order: 2,
  },
  {
    service: SERVICES.DirectRouting,
    categoryId: PRODUCT_CATEGORY_ENUM.TeamsCallRecordingServices,
    order: 3,
  },
  {
    service: SERVICES.DirectRouting,
    categoryId: PRODUCT_CATEGORY_ENUM.AddOn,
    order: 4,
  },
  {
    service: SERVICES.DirectRouting,
    categoryId: PRODUCT_CATEGORY_ENUM.DeploymentServices,
    order: 5,
  },
  {
    service: SERVICES.DirectRouting,
    categoryId: PRODUCT_CATEGORY_ENUM.MonthlySupportServices,
    order: 6,
  },
  {
    service: SERVICES.MaxCloudCoreView,
    categoryId: PRODUCT_CATEGORY_ENUM.BaseIndividualUserLicenses,
    order: 1,
    expandDefault: true,
  },
  {
    service: SERVICES.MaxCloudCoreView,
    categoryId: PRODUCT_CATEGORY_ENUM.AddOn,
    order: 2,
    expandDefault: true,
  },
  {
    service: SERVICES.MaxCloudCoreView,
    categoryId: PRODUCT_CATEGORY_ENUM.DeploymentServices,
    order: 3,
  },
  {
    service: SERVICES.MaxCloudCoreView,
    categoryId: PRODUCT_CATEGORY_ENUM.MonthlySupportServices,
    order: 3,
  },
]

export const BUNDLE_GROUPS = [
  {
    service: SERVICES.DirectRouting,
    products: [1225, 2202, 2203],
    onlyOneSelection: true,
  },
  {
    service: SERVICES.DirectRouting,
    products: [1146, 1148, 1149, 1150],
    onlyOneSelection: true,
  },
  {
    service: SERVICES.DirectRouting,
    products: [2139, 2170, 2171],
    onlyOneSelection: true,
  },

  {
    service: SERVICES.DirectRouting,
    products: [2264, 2265],
    onlyOneSelection: true,
    // dependents: [2104],   //Removed disabled & dependent state for TollFreeNumber
  },
  {
    service: SERVICES.DirectRouting,
    products: [2262, 2263],
    dependents: [2261],
  },
  {
    service: SERVICES.DirectRouting,
    products: [1120, 1991, 1994],
    dependents: [1119, 2112],
  },
  {
    service: SERVICES.DirectRouting,
    products: [1296, 1221, 1291, 1292, 1293, 1294, 1295],
    dependents: [2260], //Removed 1971 and 1934
    free : [1934],
    onlyOneSelection: true,
  },
  {
    service: SERVICES.DirectRouting,
    products: [1934],
    dependents: [1971],
    onlyOneSelection: true,
  },
  {
    service: SERVICES.MaxCloudCoreView,
    packages: [1042],
    dependents: [2268],
  },
  {
    service: SERVICES.MaxCloudCoreView,
    products: [2139, 2170, 2171],
    onlyOneSelection: true,
  },
  {
    service: SERVICES.MaxCloudCoreView,
    products: [1146, 1148, 1149, 1150],
    onlyOneSelection: true,
  },
  {
    service: SERVICES.MaxCloudCoreView,
    products: [2264, 2265],
    onlyOneSelection: true,
  },
]

export const BUNDLE_ATTRIBUTES = [
  {
    service: SERVICES.DirectRouting,
    productId: 2112,
    qtyMappings: [
      {
        productId: 1119,
        times: 50,
      },
      {
        productId: 1120,
        times: 1,
      },
    ],
  },
  {
    service: SERVICES.MaxCloudCoreView,
    productId: 2112,
    defaultQty: 10,
    qtyMappings: [
      {
        packageId: 1043,
        times: 10,
      },
      {
        packageId: 1042,
        times: 1,
      },
    ],
  },
  {
    service: SERVICES.MaxCloudCoreView,
    productId: 2268,
    qtyMappings: [
      {
        packageId: 1042,
        times: 1,
      },
    ],
  },
]

export const BUNDLE_QTY_PACKAGES = {
  [SERVICES.MaxCloudCoreView]: [1042],
}

export const BUNDLE_QTY_PRODUCTS = {
  [SERVICES.DirectRouting]: [1225, 1227, 1337, 2104, 2105, 2065, 2067, 1120],
  [SERVICES.MaxCloudCoreView]: [1225, 1227, 1337, 2104, 2105, 2065, 2067],
}
