import jwt_decode from 'jwt-decode'
import { useLocation, Navigate } from 'react-router-dom'
import { COMPANY_LEVELS } from '../../../helpers/Constants'

//
export const AuthGuard = ({ children, companyLevel }) => {
  let isAuthenticated = false
  const location = useLocation()
  const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
  if (decodeToken) {
    const decoded = jwt_decode(decodeToken)
    isAuthenticated = decoded?.company_id && decoded?.company_level
  }
  if (isAuthenticated) return children
  return <Navigate to="/login" state={{ from: location }} replace={true} />
}

export const NavigateTo = ({ children }) => {
  const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
  if (decodeToken) {
    const decoded = jwt_decode(decodeToken)
    const getCompanyLevel = decoded?.company_level
    if (getCompanyLevel == COMPANY_LEVELS.master) {
      return <Navigate to="/master" replace />
    } else if (getCompanyLevel == COMPANY_LEVELS.serviceprovider) {
      return <Navigate to="/serviceprovider" replace />
    } else if (getCompanyLevel == COMPANY_LEVELS.reseller) {
      return <Navigate to="/reseller" replace />
    } else if (getCompanyLevel == COMPANY_LEVELS.customer) {
      return <Navigate to="/customer" replace />
    } else if (getCompanyLevel == COMPANY_LEVELS.salesRep) {
      return <Navigate to="/salesrep" replace />
    } else {
      return <Navigate to="/login" replace />
    }
  } else {
    return <Navigate to="/login" replace />
  }
}
