import { Grid, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { FlexBox } from '../../../styles/styled-components/StyledContainers'
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ERROR_MESSAGES } from '../../../../helpers/constants/ErrorMessages';
import { PATTERNS } from '../../../../helpers/constants/ValidationPatterns';


const PasswordValidator = ({ password, isShow = true, isTouched, isSubmit, currentPassword }) => {
    // State to store validation errors
    const theme = useTheme();
    const [isDisabled, setIsDisabled] = useState(true);
    const [errors, setErrors] = useState([{
        isValid: false,
        message: ERROR_MESSAGES.PASSWORD_LENGTH_ERROR,
    },
    {
        isValid: false,
        message: ERROR_MESSAGES.PASSWORD_PATTERN_ERROR,
    },
    {
        isValid: false,
        message: ERROR_MESSAGES.PASSWORD_PATTERN_SPECIAL_CHARACTERS,
    },
    {
        isValid: false,
        message: ERROR_MESSAGES.PASSWORD_DIFFERENT_ERROR,
    }]);

    useEffect(() => {
        // Validate password length
        const isPasswordLengthError = password?.length >= 8;

        // Validate password patterns (uppercase, lowercase, number)
        const isPasswordContainPatters = /[a-z]/.test(password) && /[A-Z]/.test(password) && /[0-9]/.test(password);

        // Validate special characters in password
        const isPasswordContainSpecialCharacters = PATTERNS.SPECIAL_CHARACTERS.test(password);

        // Validate password match
        const isCurrentPasswordMatch = password !== currentPassword


        // Update errors state based on validations
        setErrors([
            {
                ...errors[0],
                isValid: isPasswordLengthError,
            },
            {
                ...errors[1],
                isValid: isPasswordContainPatters,
            },
            {
                ...errors[2],
                isValid: isPasswordContainSpecialCharacters,
            },
            {
                ...errors[3],
                isValid: isCurrentPasswordMatch,
            },
        ]);
        if (password?.length || isTouched || isSubmit) setIsDisabled(false)
        else setIsDisabled(true)
    }, [password, isTouched, isSubmit]);


    const getColors = useCallback((isValid) => {
        const iconColor = isDisabled ? theme.palette.grey[400] : isValid ? 'success.main' : 'error.main';
        const textColor = isDisabled ? theme.palette.grey[400] : isValid ? 'success.main' : 'error.main';
        return { iconColor, textColor };
    }, [isDisabled]);

    return (
        <>
            {isShow && (
                <FlexBox mt={2} alignItems="start !important" flexDirection="column">
                    {errors.map((error, index) => {
                        // it will validate the current password (index 3) if current password is null then we dont need to render the index 3 item or error message 
                        if (index <= 2 || (index === 3 && currentPassword)) {
                            return <FlexBox key={error.message}>
                                {error.isValid ? (
                                    <CheckCircleIcon sx={{ color: getColors(error.isValid).iconColor }} fontSize="0.75rem" />
                                ) : (
                                    <CancelIcon sx={{ color: getColors(error.isValid).iconColor }} fontSize="0.75rem" />
                                )}
                                <Typography
                                    variant="caption"
                                    ml={1}
                                    key={index}
                                    fontSize="0.75rem"
                                    color={getColors(error.isValid).textColor}
                                >
                                    {error.message}
                                </Typography>
                            </FlexBox>
                        }
                    })}
                </FlexBox>
            )}
        </>
    );
};

export default PasswordValidator