import { useEffect, useRef, useState } from 'react'
import {
  Box,
  Step,
  StepLabel,
  Stepper,
  Grid,
  Button,
  Stack,
  CircularProgress,
} from '@mui/material'

import logo from '../../../../assets/Altigen_logo_with_text.png'
import './steppers.css'
import nextbtn from '../../../../assets/icons/Next Button.svg'
import backbtn from '../../../../assets/icons/Back Button.svg'
import { useParams } from 'react-router-dom'
import CompanyDetails from './company-details/CompanyDetails'
import SaveAsIcon from '@mui/icons-material/SaveAs'
import {
  StepBackward,
  StepForward,
  setRevParent,
  setSIPDetails,
} from '../../../../store/slice/stepperSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { CompanyDraft } from '../../../../api/post/postApi'
import { saveforlatercompanydraft } from '../../../../api/get/getApi'
import {
  formDetailsState,
  setcompanyName,
  setEmail,
  setCompanyDraftAll,
} from '../../../../store/slice/stepperSlice'
import Appearance from './appearance/Appearance'
import AccountDetails from './account-details/Accountdetails'
import ResellerBilling from './billing/Billinginfo'
import StepperPayment from './payment/Payment'
import BillingType from './billing/BillingType'
import { decodeToken, normalizeApiResponse } from '../../../../helpers/HelperFunctions'
import {
  COMPANY_LEVELS,
  CUSTOMER_SIP_STEPS,
  CUSTOMER_STEPS,
  DIRECT_CUSTOMER_SIP_STEPS,
  RESELLER_DIRECT_CUSTOMER_STEPS,
  SERVICE_PROVIDER_STEPS,
  SERVICES,
} from '../../../../helpers/Constants'
import { useToastContext } from '../../../../App'
import ConsentAndAcknowledgement from './consent-and-acknowledgement/ConsentAndAcknowledgement'
import SIPDetails from './sip-details/SIPDetails'
import { FlexBox } from '../../../../common/styles/styled-components/StyledContainers'

function SignupStepper(props) {
  const dispatch = useDispatch()
  const { toast } = useToastContext()
  const {
    appearance,
    accountDetail,
    billing,
    companyDetail,
    creditCard,
    bankAccount,
    billingType,
    step,
    isRevParent,
    altigenDirectCustomer,
    companyLevel,
    directCustomer,
    isAltigenBilling,
    paymentType,
    service,
    termsAndConditionBase64String,
    isTermsAndConditionsAccepted,
    sipDetails,
  } = useSelector(formDetailsState)
  useEffect(() => {
    if (localStorage.getItem('sd_cp.jwt:tkn')) {
      dispatch(
        setEmail(
          decodeToken(localStorage.getItem('sd_cp.jwt:tkn')).user_emailaddress
        )
      )
      dispatch(
        setcompanyName(
          decodeToken(localStorage.getItem('sd_cp.jwt:tkn')).company_name
        )
      )
    } else {
      dispatch(setEmail(localStorage.getItem('email')))
      dispatch(setcompanyName(localStorage.getItem('companyname')))
    }
  }, [])

  const navigate = useNavigate()
  let [steps, setSteps] = useState([])
  const [dynamicGridleft, setdynamicGridleft] = useState(5)
  const [dynamicGridright, setdynamicGridright] = useState(7)
  const [borderRightstyle, setborderRight] = useState(false)
  const [borderLeftStyle, setborderLeft] = useState(false)
  const [serviceCode, setServiceCode] = useState(0)
  const [height, setHeight] = useState(0)
  const ref = useRef(null)
  const companyid = localStorage.getItem('companyid')

  const { signUpId } = useParams()

  const nulltoemptyfromobj = async (arr) => {
    let newobj = {}
    const prom = Object.keys(arr).map((item) => {
      return (newobj = { ...newobj, [item]: arr[item] ?? '' })
    })
    const wait = await Promise.all([prom])
    return newobj
  }

  useEffect(() => {
    getcompanydraftbyId()
  }, [])

  useEffect(() => { }, [steps])

  const getcompanydraftbyId = async () => {
    const getcompanyid = localStorage.getItem('companyid')
    if(getcompanyid === null) return navigate('/login');
    const data = await saveforlatercompanydraft(getcompanyid)
    if (data) {
      if (data.data.result.companyLevel == COMPANY_LEVELS.serviceprovider) {
        setSteps(SERVICE_PROVIDER_STEPS)
      } else if (data.data.result.isAltigenBilling) {
        setSteps(RESELLER_DIRECT_CUSTOMER_STEPS)
      } else if (
        (data.data.result.companyLevel == COMPANY_LEVELS.reseller ||
          data.data.result.companyLevel == COMPANY_LEVELS.customer) &&
        !data.data.result.isAltigenBilling
      ) {
        data.data.result?.service === SERVICES.SipTrunking &&
          data.data.result.companyLevel == COMPANY_LEVELS.customer
          ? setSteps(CUSTOMER_SIP_STEPS)
          : setSteps(CUSTOMER_STEPS)
      }
      if (
        data.data.result.companyLevel == COMPANY_LEVELS.customer &&
        !data.data.result.directCustomer
      ) {
        data.data.result?.service === SERVICES.SipTrunking
          ? setSteps(CUSTOMER_SIP_STEPS)
          : setSteps(CUSTOMER_STEPS)
      } else if (
        data.data.result.companyLevel == COMPANY_LEVELS.customer &&
        data.data.result.directCustomer
      ) {
        data.data.result?.service === SERVICES.SipTrunking
          ? setSteps(DIRECT_CUSTOMER_SIP_STEPS)
          : setSteps(RESELLER_DIRECT_CUSTOMER_STEPS)
      }
      if (data.data.result.companyLevel == COMPANY_LEVELS.customer) {
        setServiceCode(data.data.result?.service)
      }
      let billingConcatObj = null
      if (data.data.result.billingContact || data.data.result.billingAddress) {
        billingConcatObj = {
          ...data.data.result.billingContact,
          ...data.data.result.billingAddress,
        }
      }
      dispatch(setSIPDetails(data.data.result.sipDetails))
      dispatch(
        setCompanyDraftAll({
          accountDetail: data.data.result.accountDetail
            ? await nulltoemptyfromobj(data.data.result.accountDetail)
            : {
              email: localStorage.getItem('email'),
              firstName: '',
              lastName: '',
              phone: '',
              extNumber: '',
            },
          companyDetail: data.data.result.companyDetail
            ? await nulltoemptyfromobj(data.data.result.companyDetail)
            : {
              companyName: localStorage.getItem('companyname'),
              street: '',
              country: '',
              city: '',
              state: '',
              zip: '',
              phone: '',
              fax: '',
            },
          billing: billingConcatObj
            ? await nulltoemptyfromobj(billingConcatObj)
            : {
              email: '',
              firstName: '',
              lastName: '',
              mobile: '',
              fax: '',
              phone: '',
              phoneExtension: '',
              companyName: '',
              country: 'USA',
              state: '',
              city: '',
              zip: '',
              line1: '',
              line2: '',
            },
          creditCard: data.data.result.paymentMethod?.creditCard
            ? await nulltoemptyfromobj(
              data.data.result.paymentMethod?.creditCard
            )
            : {
              firstName: '',
              lastName: '',
              cardNumber: '',
              expriationMonth: 0,
              expirationYear: 0,
              cvv: '',
            },
          bankAccount: data.data.result.paymentMethod?.bankAccount
            ? await nulltoemptyfromobj(
              data.data.result.paymentMethod?.bankAccount
            )
            : {
              accountName: '',
              accountNumber: '',
              routingNumber: '',
              accountType: '',
              bankName: '',
              checkNumber: '',
            },
          appearance: data.data.result.appearance
            ? await nulltoemptyfromobj(data.data.result.appearance)
            : {
              logoUrl: '',
            },
          billingType: data.data.result.billingType
            ? await nulltoemptyfromobj(data.data.result.billingType)
            : {
              billingType: 1,
            },
          step: data.data.result.step,
          isRevParent: data.data.result?.isRevParent,
          altigenDirectCustomer: data.data.result?.altigenDirectCustomer,
          companyLevel: data.data.result.companyLevel,
          isAltigenBilling: data.data.result.isAltigenBilling,
          directCustomer: data.data.result.directCustomer,
          paymentType: data.data.result.paymentMethod?.creditCard
            ? 'card'
            : data.data.result.paymentMethod?.bankAccount
              ? 'bank'
              : 'card',
        })
      )
    }
  }

  // const step5_4 = () => {
  //   if (companyLevel == COMPANY_LEVELS.serviceprovider || (companyLevel == COMPANY_LEVELS.reseller && isAltigenBilling)) {
  //     if (companyLevel == COMPANY_LEVELS.serviceprovider && billingType.billingType == 2) {
  //       dispatch(StepBackward(2))
  //     } else {
  //       dispatch(StepBackward())
  //     }
  //   } else if (companyLevel == COMPANY_LEVELS.customer && directCustomer === false) {
  //     dispatch(StepBackward(4))
  //   }
  //   else if (companyLevel == COMPANY_LEVELS.customer && isAltigenBilling) {
  //     if (companyLevel == COMPANY_LEVELS.serviceprovider && billingType.billingType == 2) {
  //       dispatch(StepBackward(2))
  //     } else {
  //       dispatch(StepBackward())
  //     }
  //   } else if ((companyLevel == COMPANY_LEVELS.reseller || companyLevel == COMPANY_LEVELS.customer) && !isAltigenBilling) {
  //     dispatch(StepBackward(3))
  //   }
  // }

  const [logourlhelpertext, setlogourlhelpertext] = useState('')

  const step5_6 = () => {
    if (appearance.logoUrl) {
      setlogourlhelpertext('')
      dispatch(StepForward())
    } else {
      setlogourlhelpertext('Upload your company logo')
    }
  }
  // const nextStep = () => {
  //   if (companyLevel == 2) {
  //     if (billingType.billingType == 2) {
  //       dispatch(StepForward(2))
  //     } else {
  //       dispatch(StepForward())
  //     }
  //   } else if ((companyLevel == COMPANY_LEVELS.reseller || companyLevel == COMPANY_LEVELS.customer) && isAltigenBilling) {
  //     dispatch(StepForward(2))
  //   } else if ((companyLevel == 3 || companyLevel == 4) && !isAltigenBilling) {
  //     dispatch(StepForward(3))
  //   }
  // }

  const [loading, setloading] = useState(false)

  const companydraft = async (isSaveforlater, values) => {
    setloading(true)
    if (billingType.billingType == 1) {
      dispatch(setRevParent(true))
    } else {
      dispatch(setRevParent(false))
    }
    const companyData = {
      companyId: companyid,
      step: step,
      accountDetail: step == 0 ? values : accountDetail,
      companyDetail: step == 1 ? values : companyDetail,
      appearance,
      isRevParent,
      altigenDirectCustomer,
      companyLevel,
      isAltigenBilling,
      billingType,
      isSubmitted: isTermsAndConditionsAccepted,
      isTermsAndConditionsAccepted: isTermsAndConditionsAccepted,
      termsAndConditionsFileData: termsAndConditionBase64String,
      service: service,
      sipDetails: sipDetails,
      billingContact:
        step == 2
          ? {
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            phoneExtension: values.phoneExtension,
            mobile: values.mobile,
            fax: values.fax,
            email: values.email,
          }
          : {
            firstName: billing.firstName,
            lastName: billing.lastName,
            phone: billing.phone,
            phoneExtension: billing.phoneExtension,
            mobile: billing.mobile,
            fax: billing.fax,
            email: billing.email,
          },
      billingAddress:
        step == 2
          ? {
            companyName: values.companyName,
            state: values.state,
            city: values.city,
            line1: String(values.line1),
            line2: values.line2,
            country: values.country,
            zip: values.zip?.toString(),
          }
          : {
            companyName: billing.companyName,
            state: billing.state,
            city: billing.city,
            line1: String(billing.line1),
            line2: billing.line2,
            country: billing.country,
            zip: billing.zip?.toString(),
          },
      paymentMethod: {
        creditCard:
          paymentType == 'card'
            ? step == 3 || step == 4
              ? {
                ...values,
                cardNumber: values?.cardNumber?.toString(),
                cvv: values?.cvv?.toString(),
              }
              : {
                ...creditCard,
                cardNumber: creditCard?.cardNumber?.toString(),
                cvv: creditCard?.cvv?.toString(),
              }
            : null,
        bankAccount:
          paymentType == 'bank'
            ? step == 3 || step == 4
              ? values
              : bankAccount
            : null,
      },
    }
    try {
      if (companyData.paymentMethod?.creditCard) {
        if (companyData.paymentMethod?.creditCard?.expriationMonth == null) {
          companyData.paymentMethod.creditCard.expriationMonth = 0
        }
        if (companyData.paymentMethod?.creditCard?.expirationYear == null) {
          companyData.paymentMethod.creditCard.expirationYear = 0
        }
      }
      // const billTypeValue = companyData.billingType.billingType;
      // companyData.billingType ={};
      // if(companyLevel == 2) {
      //   companyData.billingType.billingType = billTypeValue
      // }
      // if(companyLevel == 3) {
      //   companyData.billingType.resellerBillingType = billTypeValue;
      // }

      const data = await CompanyDraft(companyData, props?.config)
      if (isSaveforlater) {
        setloading(false)
        toast.showToast('Draft saved successfully')
      } else {
        setloading(false)
        localStorage.removeItem('email')
        toast.showToast('You have successfully completed signup')
        setTimeout(() => {
          navfunction()
        }, 3000)
      }
    } catch (error) {
      if (isSaveforlater) {
        setloading(false)
        toast.showToast( normalizeApiResponse(error)?.message, 'error')
      } else {
        if (
          companyData.appearance &&
          companyData.appearance.logoUrl == '' &&
          isSaveforlater == false
        ) {
          toast.showToast(
            'File is not uploaded, Please press confirm button',
            'error'
          )
        } else {
          toast.showToast( normalizeApiResponse(error)?.message, 'error')
        }
        setloading(false)
      }
    }
  }

  const navfunction = () => {
    localStorage.clear()
    sessionStorage.clear()
    navigate('/login')
  }

  const getServiceProviderSteps = () => {
    switch (step) {
      case 0:
        return <AccountDetails companydraft={companydraft} />
      case 1:
        return (
          <>
            <CompanyDetails companydraft={companydraft} />
          </>
        )
      case 2:
        return (
          <>
            <ResellerBilling companydraft={companydraft} />
          </>
        )
      case 3:
        return (
          <>
            <BillingType config={props.config} />
            <Grid container columnSpacing={4}>
              <Grid item md={6} sm={6} xs={6}>
                <Stack
                  direction="row"
                  sx={{ pl: '24px', marginTop: '-12px' }}
                  spacing={2}
                >
                  <img
                    src={backbtn}
                    className="btn-hover-effect"
                    height="92px"
                    onClick={() => dispatch(StepBackward())}
                    alt="next button"
                  />
                  <img
                    src={nextbtn}
                    className="btn-hover-effect"
                    height="92px"
                    onClick={() => dispatch(StepForward())}
                    alt="next button"
                  />
                </Stack>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <Stack
                  style={{
                    justifyContent: 'right',
                    padding: ' 15px 3rem 0px 10px',
                  }}
                  direction="row"
                  spacing={2}
                >
                  <Button
                    variant="contained"
                    onClick={() => companydraft(true)}
                  >
                    <SaveAsIcon /> &nbsp; save for later
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </>
        )

      case 4:
        return (
          <>
            <StepperPayment companydraft={companydraft} />
          </>
        )
      case 5:
        return (
          <>
            <ConsentAndAcknowledgement />
          </>
        )
      default:
        return (
          <>
            <Appearance
              config={props.config}
              logohelpertext={logourlhelpertext}
            />
            <br/>
            <FlexBox gap={2} className='fullWidth' alignItems='center'>
              <img
                src={backbtn}
                className="btn-hover-effect"
                height="92px"
                onClick={() => dispatch(StepBackward())}
                alt="back button"
              />
              <Button
                fullWidth
                variant="contained"
                type="button"
                onClick={() => companydraft(false)}
              >
                Submit &emsp;
                {loading ? (
                  <CircularProgress sx={{ color: '#fff' }} size={20} />
                ) : null}
              </Button>
            </FlexBox>
          </>
        )
}
  }

const getResellerOrDirectCustomerSteps = () => {
  switch (step) {
    case 0:
      return (
        <>
          <AccountDetails companydraft={companydraft} />
        </>
      )
    case 1:
      return (
        <>
          <CompanyDetails companydraft={companydraft} />
        </>
      )
    case 2:
      return (
        <>
          <ResellerBilling companydraft={companydraft} />
        </>
      )

    case 3:
      return (
        <>
          <StepperPayment companydraft={companydraft} />
        </>
      )
    case 4:
      return (
        <>
          <ConsentAndAcknowledgement />
        </>
      )
    default:
      return (
        <>
          <Appearance
            config={props.config}
            logohelpertext={logourlhelpertext}
          />
           <FlexBox padding='0 20px' className='fullWidth' alignItems='center'>
              <img
                src={backbtn}
                className="btn-hover-effect"
                height="92px"
                onClick={() => dispatch(StepBackward())}
                alt="back button"
              />
              <Button
                fullWidth
                variant="contained"
                type="button"
                onClick={() => companydraft(false)}
              >
                Submit &emsp;
                {loading ? (
                  <CircularProgress sx={{ color: '#fff' }} size={20} />
                ) : null}
              </Button>
            </FlexBox>
        </>
      )
  }
}

const getCustomerSteps = () => {
  switch (step) {
    case 0:
      return (
        <>
          <AccountDetails companydraft={companydraft} />
        </>
      )
    case 1:
      return (
        <>
          <CompanyDetails companydraft={companydraft} />
        </>
      )
    case 2:
      return <ConsentAndAcknowledgement />
    default:
      return (
        <>
          <Appearance
            config={props.config}
            logohelpertext={logourlhelpertext}
          />
          <Box>
            <Grid container columnSpacing={4}>
              <Grid item md={6} sm={6} xs={6}>
                <Stack direction="row" sx={{ pl: '24px' }} spacing={2}>
                  <img
                    src={backbtn}
                    className="btn-hover-effect"
                    height="92px"
                    onClick={() => dispatch(StepBackward())}
                    alt="back button"
                  />
                </Stack>
              </Grid>
              <Grid item md={6}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  height="92px"
                  sx={{
                    justifyContent: 'right',
                    padding: ' 0px 3rem 0px 10px',
                    marginTop: '-15px',
                  }}
                  spacing={2}
                >
                  <Box sx={{ ml: '30px' }}>
                    <Button
                      style={{ marginTop: '28px' }}
                      variant="contained"
                      type="button"
                      onClick={() => companydraft(false)}
                    >
                      Submit &emsp;
                      {loading ? (
                        <CircularProgress sx={{ color: '#fff' }} size={20} />
                      ) : null}
                    </Button>
                  </Box>
                  {/*<Button variant='contained' onClick={() => companydraft(true)}><SaveAsIcon /> &nbsp; save for later</Button>*/}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </>
      )
  }
}

const getCustomerSIPSteps = () => {
  if (companyLevel == COMPANY_LEVELS.customer && !directCustomer) {
    switch (step) {
      case 0:
        return <AccountDetails companydraft={companydraft} />
      case 1:
        return <CompanyDetails companydraft={companydraft} />
      case 2:
        return <SIPDetails />
      case 3:
        return <ConsentAndAcknowledgement />
      default:
        return (
          <>
            <Appearance
              config={props.config}
              logohelpertext={logourlhelpertext}
            />
            <Box>
              <Grid container columnSpacing={4}>
                <Grid item md={6} sm={6} xs={6}>
                  <Stack direction="row" sx={{ pl: '24px' }} spacing={2}>
                    <img
                      src={backbtn}
                      className="btn-hover-effect"
                      height="92px"
                      onClick={() => dispatch(StepBackward())}
                      alt="back button"
                    />
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    height="92px"
                    sx={{
                      justifyContent: 'right',
                      padding: ' 0px 3rem 0px 10px',
                      marginTop: '-15px',
                    }}
                    spacing={2}
                  >
                    <Box sx={{ ml: '30px' }}>
                      <Button
                        style={{ marginTop: '28px' }}
                        variant="contained"
                        type="button"
                        onClick={() => companydraft(false)}
                      >
                        Submit &emsp;
                        {loading ? (
                          <CircularProgress
                            sx={{ color: '#fff' }}
                            size={20}
                          />
                        ) : null}
                      </Button>
                    </Box>
                    {/*<Button variant='contained' onClick={() => companydraft(true)}><SaveAsIcon /> &nbsp; save for later</Button>*/}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </>
        )
    }
  } else if (companyLevel == COMPANY_LEVELS.customer && directCustomer) {
    switch (step) {
      case 0:
        return (
          <>
            <AccountDetails companydraft={companydraft} />
          </>
        )
      case 1:
        return (
          <>
            <CompanyDetails companydraft={companydraft} />
          </>
        )
      case 2:
        return (
          <>
            <ResellerBilling companydraft={companydraft} />
          </>
        )

      case 3:
        return (
          <>
            <StepperPayment companydraft={companydraft} />
          </>
        )
      case 4:
        return (
          <>
            <SIPDetails />
          </>
        )
      case 5:
        return (
          <>
            <ConsentAndAcknowledgement />
          </>
        )
      default:
        return (
          <>
            <Appearance
              config={props.config}
              logohelpertext={logourlhelpertext}
            />
            <Box>
              <Grid container columnSpacing={4}>
                <Grid item md={6} sm={6} xs={6}>
                  <Stack direction="row" sx={{ pl: '24px' }} spacing={2}>
                    <img
                      src={backbtn}
                      className="btn-hover-effect"
                      height="92px"
                      onClick={() => dispatch(StepBackward())}
                      alt="back button"
                    />
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    height="92px"
                    sx={{
                      justifyContent: 'right',
                      padding: ' 0px 3rem 0px 10px',
                      marginTop: '-15px',
                    }}
                    spacing={2}
                  >
                    <Box sx={{ ml: '30px' }}>
                      <Button
                        style={{ marginTop: '28px' }}
                        variant="contained"
                        type="button"
                        onClick={() => companydraft(false)}
                      >
                        Submit &emsp;
                        {loading ? (
                          <CircularProgress
                            sx={{ color: '#fff' }}
                            size={20}
                          />
                        ) : null}
                      </Button>
                    </Box>
                    {/*<Button variant='contained' onClick={() => companydraft(true)}><SaveAsIcon /> &nbsp; save for later</Button>*/}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </>
        )
    }
  }
}

return (
  <>
    <Grid container ref={ref}>
      <Grid
        item
        md={dynamicGridleft}
        sm={dynamicGridleft}
        xs={4}
        sx={{
          paddingLeft: '65px',
          backgroundColor: '#F9FAFC',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            marginTop: '50px',
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ width: '400px', height: 'calc(100vh-101)' }}
          />
        </Box>
        <Box>
          <Stepper
            activeStep={step}
            style={{
              width: '100%',
              height: '100px',
              alignItems: 'flex-start',
            }}
            orientation="vertical"
          >
            {steps.map((label) => {
              const stepProps = {}
              const labelProps = {}

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </Box>
      </Grid>
      <Grid item md={dynamicGridright} sm={dynamicGridright} xs={8}>
        <Box sx={{ marginTop: '50px', height: 'calc(100vh-101)' }}>
          {companyLevel == COMPANY_LEVELS.serviceprovider
            ? getServiceProviderSteps()
            : (companyLevel == COMPANY_LEVELS.reseller ||
              (companyLevel == COMPANY_LEVELS.customer &&
                directCustomer)) &&
              isAltigenBilling &&
              serviceCode !== SERVICES.SipTrunking
              ? getResellerOrDirectCustomerSteps()
              : companyLevel == COMPANY_LEVELS.customer &&
                !directCustomer &&
                serviceCode !== SERVICES.SipTrunking
                ? getCustomerSteps()
                : companyLevel == COMPANY_LEVELS.customer &&
                  serviceCode === SERVICES.SipTrunking
                  ? getCustomerSIPSteps()
                  :  serviceCode == 0 && getResellerOrDirectCustomerSteps() }

        </Box>
      </Grid>
    </Grid>
  </>
)
}

export default SignupStepper
